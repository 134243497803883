import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'NxtComm',
    useCase: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT',
    description: `NxtComm is revolutionizing connectivity in the air and on land. This is an on-going client engagement. I have been designing the next generation of the in-flight media experience. From streaming your favorite shows, to loging in with your personal devices the NxComm seat back NxtSteam experience will be an amazing upgrade to the mediocre in-flight entertainment options currently available.

    I will add more to this as it develops. `,
  },
  caseId: 'nxt-comm',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
